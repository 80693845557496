<template>
  <Card class="px-3 py-3 flex flex-col width-2000px gap-3">
    <template>
      <SummaryTable
        :headers="headers"
        :items="data.reviews"
        :footer="data.footer"
        :title="data.title"
        :subtitle="data.subtitle"
        :colspan="7"
      >
        <template v-slot:header="{ header }">
          <div class="flex justify-start items-center mb-5 gap-2">
            <h1 class="font-bold text-lg leading-6 tracking-wide text-flame">
              {{ header.title }}
            </h1>
            <h2 class="font-semibold text-base leading-5 tracking-wide text-romanSilver">
              ({{ header.subtitle }})
            </h2>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div v-if="item.objectives" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.objectives }}
            </span>
          </div>
          <div v-if="item.criteria" class="text-darkPurple font-normal text-base leading-5">
            <span class="" v-for="(value, i) in item.data.criteria" :key="`${item}-${i}`">
              {{ value }};
            </span>
          </div>
          <div v-if="item.outcome" class="flex justify-start items-center gap-2">
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.outcome }}
            </span>
          </div>
          <div v-if="item.comment" class="flex justify-start items-center gap-2 w-72">
            <span class="text-darkPurple font-normal text-base leading-5"
              >
              <Textarea placeholder="--Enter--" v-model="item.data.comment" @input="setInput($event)" :height="58" />
            </span>
          </div>
          <div v-if="item.documentUrl" @click="uploadModal = !uploadModal">
            <div class="flex justify-start items-center gap-2 w-56 cursor-pointer">
              <div class="text-flame text-base">
                <Icon icon-name="attachment" size="xs" />
              </div>
              <span class="text-flame text-xs font-black leading-5 uppercase">
                View/Upload
              </span>
            </div>
          </div>
          <div v-if="item.ratingId" class="flex flex-col justify-center items-center w-40">
            <span class="text-darkPurple font-semibold text-base leading-5">
              <TextInput type="number" :value="item.data.ratingId" :disabled="true" />
            </span>
          </div>
          <div v-if="item.score" class="w-32 mt-2">
            <Button class="flex justify-center items-center h-10 border border-romanSilver bg-cultured">
              <span class="text-base font-normal leading-5 text-romanSilver">{{item.data.score}}</span>
            </Button>
          </div>
        </template>
        <template v-slot:footer="{ footer }">
          <div class="flex justify-between items-center px-5">
              <span class="font-bold text-lg leading-5 text-darkPurple">Total</span>
              <div class="border-b border-dashed border-romanSilver w-7/12" />
              <div class="flex justify-between items-center gap-6">
                <div class="flex flex-col justify-start items-start">
                  <span class="font-black text-xs leading-6 uppercase text-romanSilver">
                    Total Weighted Score
                  </span>
                  <div class="flex justify-start items-center gap-2">
                    <span class="font-bold text-xl leading-5 text-blueCrayola">
                      {{ footer.score }}
                    </span>
                    <span class="text-xs text-jet font-semibold leading-5"></span>
                  </div>
                </div>
                <div class="flex flex-col justify-start items-start">
                  <span class="font-black text-xs leading-6 uppercase text-romanSilver">
                    % Score
                  </span>
                  <span class="font-bold text-xl leading-5 text-desire">
                   ---%
                  </span>
                </div>
              </div>
            </div>
        </template>
      </SummaryTable>
    </template>
    <UploadModal :open-modal="uploadModal" />
  </Card>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
  import TextInput from "@scelloo/cloudenly-ui/src/components/text";
  import SummaryTable from "@/components/SummaryTable";
  import Button from "@/components/Button";
  import UploadModal from "../Modals/UploadModal";

  export default {
    name: "ESSNextSteps",
    components: {
      Card,
      Button,
      Textarea,
      TextInput,
      SummaryTable,
      UploadModal
    },
    props: {
      data: {
        type: Object,
        required: true,
        default: (() => {})
      },
    },
    data() {
      return {
        uploadModal: false,
        headers: [
          { title: "Review Objectives", value: "objectives", width: 30 },
          { title: "Success Criteria (KPI)", value: "criteria", width: 45 },
          { title: "Evaluation Outcome", value: "outcome", width: 25 },
          { title: 'Next Steps', value: 'comment', width: 35 },
          { title: "Supporting Document(s)", value: "documentUrl", width: 25 },
          { title: "Rating", value: "ratingId", width: 20 },
          { title: "Score", value: "score", width: 10 },
        ],
      };
    },
    methods: {
      percentage(value, total) {
        return (value / total) * 100;
      },

    setInput(value) {
      this.setPayload();

      return value;
    },

    setPayload() {
      this.reviewPayload = [];

        this.reviewPayload.push(this.data);

        const payload = this.reviewPayload;

        this.$emit("set", payload);
    },
  },

  mounted() {
    this.setPayload();
    }
  };
</script>

<style scoped>
  .text-10px {
    font-size: 10px;
  }
  .width-2000px {
    width: 2000px !important;
  }
</style>
