<template>
  <RightSideBar
    v-if="openModal"
    @close="$emit('close')"
    :loading="loading"
    submit="Import"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <span class="text-darkPurple text-lg font-bold leading-5">
       Upload file(s)
      </span>
    </template>
    <template v-slot:subtitle>
      <div class="w-full my-5">
        <span class="font-semibold text-base leading-5 text-darkPurple">
            Upload files that highlight this employee’s performance issues.
        </span>
         <FileUpload @input="handleFileUpload($event)" />
      </div>
    </template>
    <template>
      <div class="w-full border-b border-dashed border-romanSilver flex flex-col gap-3 mb-2 -mt-2">
        <span class="font-semibold text-xs leading-5 text-romanSilver uppercase">
          Uploading
        </span>
        <div class="flex w-full justify-center mb-2">
          <span class="font-semibold text-xs leading-5 text-romanSilver uppercase">
            No Uploads in Progress
          </span>
        </div>
      </div>
    </template>
    <template>
      <div class="w-full flex flex-col items-start justify-start gap-2">
        <span class="font-semibold text-xs leading-5 text-romanSilver uppercase">
          Uploading
        </span>
        <div class="flex justify-between items-center w-full">
          <div class="leading-5 flex flex-col items-start justify-start">
            <span class="font-semibold text-base text-darkPurple">okoro_andrew_issues2021.xlsx</span>
            <div class="flex items-start justify-start gap-1">
              <span class="font-normal text-xs text-blueCrayola">Uploaded June 12, 2021</span>
              <span class="font-normal text-xs text-romanSilver">• 258KB</span>
            </div>
          </div>
          <div class="flex w-full justify-end gap-2">
            <div class="bg-blue-100 w-8 h-8 justify-center items-center flex rounded-md">
              <Icon icon-name="icon-download" size="xs" class="text-blueCrayola bg-transparent" />
            </div>
            <div class="bg-red-100 w-8 h-8 justify-center items-center flex rounded-md">
              <Icon icon-name="icon-trash" size="xs" class="text-blueCrayola bg-transparent" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import FileUpload from "@scelloo/cloudenly-ui/src/components/file-upload";
import RightSideBar from "@/components/RightSideBar";
import Icon from "@/components/Icon";

export default {
  components: {
    RightSideBar,
    FileUpload,
    Icon
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    handleFileUpload(file) {
      // eslint-disable-next-line no-console
      console.log(file);
    }
  }
};
</script>

<style>
  .rightSidebar{
    width: 520px !important;
  }
</style>