<template>
  <Card class="px-3 py-3 flex flex-col width-1700px gap-3">
    <template>
      <SummaryTable
        :headers="headers"
        :items="data.reviews"
        :footer="data.footer"
        :title="data.title"
        :subtitle="data.subtitle"
        :colspan="6"
        :header="{}"
        :subheaders="{}"
      >
        <template v-slot:header="{ header }">
          <div class="flex justify-start items-center mb-5 gap-2">
            <h1 class="font-bold text-lg leading-6 tracking-wide text-flame">
              {{ header.title }}
            </h1>
            <h2
              class="font-semibold text-base leading-5 tracking-wide text-blueCrayola"
              v-if="header.subtitle === 'Current'"
            >
              ({{ header.subtitle }})
            </h2>
            <h2
              class="font-semibold text-base leading-5 tracking-wide text-romanSilver"
              v-else
            >
              ({{ header.subtitle }})
            </h2>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div
            v-if="item.objectives"
            class="flex justify-start items-center gap-2"
          >
            <span class="text-darkPurple font-normal text-base leading-5">
              {{ item.data.objectives }}
            </span>
          </div>
          <div
            v-if="item.criteria"
            class="text-darkPurple font-normal text-base leading-5"
          >
            <span
              class=""
              v-for="(value, i) in item.data.criteria"
              :key="`${item}-${i}`"
            >
              {{ value }};
            </span>
          </div>
          <div
            v-if="item.outcome"
            class="flex justify-start items-center gap-2"
          >
            <span class="text-darkPurple font-normal text-base leading-5">
              <Textarea
                v-model="item.data.outcome"
                :height="58"
                :disabled="data.subtitle !== 'Current'"
                placeholder="Enter Outcome"
                @input="setInput($event)"
              />
            </span>
          </div>
          <div v-if="item.documentUrl" @click="uploadModal = !uploadModal">
            <div
              class="flex justify-start items-center gap-2 w-56 cursor-pointer"
            >
              <div class="text-flame text-base">
                <Icon icon-name="attachment" size="xs" />
              </div>
              <span class="text-flame text-xs font-black leading-5 uppercase">
                View/Upload
              </span>
            </div>
          </div>
          <div
            v-if="item.ratingId"
            class="flex flex-col justify-center items-center"
          >
            <span
              class="text-darkPurple font-semibold -ml-8 text-base leading-5"
            >
              <select
                @change="changeRating($event, item)"
                class="mr-2 mt-1 input-select"
                rules="'required'"
                v-if="data.subtitle === 'Current'"
                v-model="item.data.ratingId"
              >
                <option value="" disabled selected>--Rating--</option>
                <option
                  v-for="option in ratingOptions"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </select>

              <TextInput
                type="number"
                :disabled="data.subtitle !== 'Current'"
                placeholder="Rating"
                v-else
              />
            </span>
          </div>
          <div v-if="item.score" class="w-32 -mt-1">
            <TextInput
              type="number"
              v-model="item.data.score"
              :disabled="true"
              placeholder="Scores"
            />
          </div>
        </template>
        <template v-slot:footer="{ footer }">
          <div class="flex justify-between items-center px-5">
            <span class="font-bold text-lg leading-5 text-darkPurple"
              >Total</span
            >
            <div class="border-b border-dashed border-romanSilver w-7/12" />
            <div class="flex justify-between items-center gap-6">
              <div class="flex flex-col justify-start items-start">
                <span
                  class="font-black text-xs leading-6 uppercase text-romanSilver"
                >
                  Total Weighted Score
                </span>
                <div class="flex justify-start items-center gap-2">
                  <span class="font-bold text-xl leading-5 text-blueCrayola">
                    {{footer.score}}
                  </span>
                  <span class="text-xs text-jet font-semibold leading-5"></span>
                </div>
              </div>
              <div class="flex flex-col justify-start items-start">
                <span
                  class="font-black text-xs leading-6 uppercase text-romanSilver"
                >
                  % Score
                </span>
                <span class="font-bold text-xl leading-5 text-mediumSeaGreen">
                </span>
              </div>
            </div>
          </div>
        </template>
      </SummaryTable>
    </template>
    <UploadModal :open-modal="uploadModal" />
  </Card>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
import TextInput from "@scelloo/cloudenly-ui/src/components/text";
import SummaryTable from "@/components/SummaryTable";
import UploadModal from "../Modals/UploadModal";

export default {
  name: "EssReviewSessions",
  components: {
    Card,
    Textarea,
    TextInput,
    SummaryTable,
    UploadModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    ratingOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      uploadModal: false,
      headers: [
        { title: "Review Objectives", value: "objectives" },
        { title: "Success Criteria (KPI)", value: "criteria" },
        { title: "Evaluation Outcome", value: "outcome", width: 18 },
        { title: "Supporting Document(s)", value: "documentUrl", width: 15 },
        { title: "Rating", value: "ratingId", width: 10 },
        { title: "Score", value: "score", width: 10 },
      ],
      reviewPayload: [],
    };
  },
  methods: {
    percentage(value, total) {
      return (value / total) * 100;
    },

    setInput(value) {
      this.setPayload();

      return value;
    },

    changeRating(event, item) {
      const ratingId = event.target.value;
      const planId = item.data.actionPlanId;

      // eslint-disable-next-line no-param-reassign
      item.data.ratingId = ratingId;

      this.$_getReviewScores(planId, ratingId).then((response) => {
        // eslint-disable-next-line no-param-reassign
        item.data.score = response.data.data.score;
      });

      this.setPayload();
    },

    setPayload() {
      let payload;
      this.reviewPayload = [];

      if (this.data.subtitle === "Current") {

        this.reviewPayload.push(this.data);

        payload = this.reviewPayload;

        this.$emit("set", payload);
      }
    },
  },

  mounted() {
    this.setPayload();
  },
};
</script>

<style>
.input-select {
  border: 1px solid #878e99;
  height: 38px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 5px;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
}

.input-select:focus {
  outline: none;
}

.input-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(/img/down-arrow.f3bc3f05.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 14px;
  padding: 3px 15px;
  width: 100%;
  padding-right: 29px;
  outline: none;
  border: 1px solid #878e99;
  box-sizing: border-box;
  border-radius: 3px;
  height: 40px;
  /* width: 100%; */
  /* width: fit-content; */
}

.label label {
  display: none;
}
</style>

<style scoped>
.text-10px {
  font-size: 10px;
}
.width-1700px {
  min-width: 1700px !important;
}
</style>
